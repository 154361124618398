
import { defineComponent, onMounted, ref } from "vue";
import Dropdown from "@/components/DropdownComponent.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { IEmployeeNewEdit } from "@/types/Employee";
import { ISetting } from "@/types/Setting";
import { IDepartment } from "@/types/Department";
import { IAddress, ICountry, IWorkLocation } from "@/types/Address";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import AuthStore from "@/store/auth-store";
import { formatDate_h, notify, useFormatDate } from "@/services/helpers";
import { useRoute, useRouter } from "vue-router";
import { IManager } from "@/types/EmployeeList";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "EmployeeDetails",
  components: { Datepicker, Dropdown, Form, Field, ErrorMessage },
  setup() {
    const workForceService = new WorkForceService();
    const departments = ref([]);
    const workLocations = ref<IWorkLocation[]>([]);
    const countries = ref<ICountry[]>([]);
    const fields = ref({ text: "name", value: "id" });
    const managers = ref<IManager[]>([]);
    const gender = ref([
      { id: 0, value: "Male" },
      { id: 1, value: "Female" },
      { id: 2, value: "Prefer not to say" },
    ]);

    const rolesEnum = {
      admin: "Admin",
      manager: "Manager",
      orgAdmin: "Org Admin",
    };

    const message = ref("");
    const authId = ref(AuthStore.getTokenData().EmployeeId);
    const authRole = ref(AuthStore.getTokenData().Roles);
    const isAdmin = ref(
      AuthStore.getTokenData().Roles == rolesEnum.admin ||
        AuthStore.getTokenData().Roles == rolesEnum.orgAdmin
    );
    const canEdit = ref(false);

    const route = useRoute();
    const router = useRouter();

    const editEmployee = ref<IEmployeeNewEdit>({
      id: 0,
      firstName: "",
      lastName: "",
      gender: "",
      homeNumber: "",
      maritalStatus: "",
      medicalConditions: "",
      mobileNumber: "",
      smsNotification: false,
      officeNumber: "",
      personalEmail: "",
      workEmail: "",
      department: "",
      departmentId: 0,
      workLocationId: 0,
      jobTitle: "",
      isActive: false,
      departmentName: "",
      manager: "",
      managerId: 0,
      addressId: 0,
      leavesQuota: 0,
      carryOverDays: 0,
      dob: "",
      jobCommencementDate: "",
      role: "",
    });

    const formatDate = formatDate_h;

    const crumbs = ref(["Dashboard", "Employees", "Edit Employee"]);

    const address = ref<IAddress>({
      id: 0,
      line1: "",
      line2: "",
      town: "",
      city: "",
      postCode: "",
      countryId: 0,
    });

    // Emergency Contact
    const loadingContact = ref(false);
    const enabled = ref(true);

    const loading = ref(false);

    const canInviteOrEdit = (loggedInRole: string, inviteeRole: string) => {
      if (
        loggedInRole == rolesEnum.manager &&
        (inviteeRole == rolesEnum.orgAdmin || inviteeRole == rolesEnum.admin)
      ) {
        return false;
      }
      return true;
    };

    const calculateAge = (date: any) => {
      const today = new Date();
      const birthDate = new Date(date);

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    };

    const save = async () => {
      loading.value = true;
      message.value = "";
      if (
        new Date(editEmployee.value.dob) >
        new Date(editEmployee.value.jobCommencementDate)
      ) {
        notify(
          "Please ensure that the date of birth (DOB) is earlier than the job commencement date.",
          "Attention",
          "danger"
        );
        loading.value = false;
        return;
      } else if (calculateAge(new Date(editEmployee.value.dob)) < 18) {
        notify(
          "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years.",
          "Attention",
          "danger"
        );
        loading.value = false;
        return;
      }
      const addressId =
        editEmployee.value.addressId > 0
          ? editEmployee.value.addressId.toString()
          : "";
      editEmployee.value.carryOverDays = parseInt(
        editEmployee.value.carryOverDays.toString()
      );
      editEmployee.value.leavesQuota = parseInt(
        editEmployee.value.leavesQuota.toString()
      );

      editEmployee.value.gender = String(editEmployee.value.gender);
      editEmployee.value.dob = formatDate(editEmployee.value.dob);
      editEmployee.value.jobCommencementDate = formatDate(
        editEmployee.value.jobCommencementDate
      );

      await workForceService
        .postOrPut<void>(
          `/address/${addressId}`,
          address.value as IAddress,
          addressId
        )
        .then((response: any) => {
          if (!response.isError) {
            if (editEmployee.value.addressId == 0) {
              editEmployee.value.addressId = response.content.id;
            }

            workForceService
              .postOrPut<void>(
                `/employee/${editEmployee.value.id}`,
                editEmployee.value as IEmployeeNewEdit,
                editEmployee.value.id.toString()
              )
              .then((response) => {
                if (!response.isError) {
                  notify(
                    "Employee updated successfully.",
                    "Success",
                    "success"
                  );
                  loading.value = false;
                } else {
                  loading.value = false;
                  notify(response.errors, "Error", "danger");
                }
              });
          } else {
            loading.value = false;
            notify(response.errors, "Error", "danger");
          }
        });
    };

    const initialize = async () => {
      loading.value = true;

      // Load Employee
      const employeeId = route.params.id;
      await workForceService
        .get<IEmployeeNewEdit>(`/employee/${employeeId}`, false)
        .then(async (response) => {
          if (
            response.content &&
            canInviteOrEdit(authRole.value, response.content.role)
          ) {
            editEmployee.value = response.content;
          } else {
            // Redirect if the user doesn't have permission
            router.back();
          }

          if (editEmployee.value.leavesQuota == 0) {
            await workForceService
              .get<ISetting[]>(`/settings/LeavesQuota`, false)
              .then((response: any) => {
                editEmployee.value.leavesQuota = parseInt(
                  response.content.value
                );
              });
          }
          if (editEmployee.value.carryOverDays == 0) {
            await workForceService
              .get<ISetting[]>(`/settings/CarryOverDays`, false)
              .then((response: any) => {
                editEmployee.value.carryOverDays = parseInt(
                  response.content.value
                );
              });
          }
          if (editEmployee.value.addressId > 0) {
            await workForceService
              .get<IAddress>(`/address/${editEmployee.value.addressId}`, false)
              .then((response: any) => {
                address.value = response.content;
              });
          }
        });

      await Promise.all([
        // Fetch other data asynchronously using Promise.all
        workForceService.get<IDepartment[]>(
          `/departments?IsActive=true&PageIndex=0&PageSize=100`,
          false
        ),
        workForceService.get<IWorkLocation[]>(`/worklocations`, false),
        workForceService.get<IManager[]>(`/employee/managers`, false),
        workForceService.get<ICountry[]>(`/countries`, false),
      ]).then((responses: any) => {
        departments.value = responses[0].content.items;
        workLocations.value = responses[1].content;
        managers.value = responses[2].content;
        countries.value = responses[3].content;
      });

      loading.value = false;
    };

    onMounted(async () => {
      canEdit.value =
        (authRole.value == rolesEnum.admin ||
          authRole.value == rolesEnum.manager ||
          authRole.value == rolesEnum.orgAdmin) &&
        route.params.id !== authId.value;

      await initialize();
    });

    const schema = yup.object().shape({
      firstName: yup
        .string()
        .required("Please provide your first name.")
        .max(75, "Must not exceed 75 characters in length."),
      lastName: yup
        .string()
        .required("Please provide your last name.")
        .max(75, "Must not exceed 75 characters in length."),
      maritalStatus: yup
        .string()
        .max(20, "Must not exceed 20 characters in length."),
      mobileNumber: yup
        .string()
        .required("Please enter your mobile number.")
        .test(
          "minLength",
          "Must be at least 7 characters in length.",
          function (value) {
            return !value || value.length >= 7;
          }
        )
        .max(14, "Must not exceed 14 characters in length."),
      officeNumber: yup
        .string()
        .test(
          "minLength",
          "Must be at least 7 characters in length.",
          function (value) {
            return !value || value.length >= 7;
          }
        )
        .max(14, "Must not exceed 14 characters in length."),
      homeNumber: yup
        .string()
        .required("Please provide your home number.")
        .test(
          "minLength",
          "Must be at least 7 characters in length.",
          function (value) {
            return !value || value.length >= 7;
          }
        )
        .max(14, "Must not exceed 14 characters in length."),
      workEmail: yup
        .string()
        .required("Please provide your work email")
        .email("Please provide a valid email address."),
      personalEmail: yup
        .string()
        .email("Please provide a valid email address."),
      departmentId: yup
        .number()
        .moreThan(0, "Please select a department from the list.")
        .required("Please select a department from the list."),
      jobTitle: yup
        .string()
        .required("Please provide your job title.")
        .max(50, "Must not exceed 50 characters in length."),
      medicalConditions: yup
        .string()
        .max(200, "Must not exceed 200 characters in length."),
      line1: yup
        .string()
        .required("Please provide the first line of your address (Line 1).")
        .max(200, "Must not exceed 200 characters in length."),
      line2: yup.string().max(200, "Must not exceed 200 characters in length."),
      town: yup
        .string()
        .required("Please provide the name of your town.")
        .max(100, "Must not exceed 100 characters in length."),
      city: yup
        .string()
        .required("Please provide the name of your city.")
        .max(50, "Must not exceed 50 characters in length."),
      countryId: yup
        .number()
        .moreThan(0, "Please select a country from the list.")
        .required("Please select a country from the list.")
        .max(15, "Must not exceed 15 characters in length."),
      postCode: yup
        .string()
        .required("Please provide your postal code.")
        .max(50, "Must not exceed 50 characters in length."),
      leavesQuota: yup
        .number()
        .min(
          0,
          "Please make sure that the input value is greater than or equal to 0."
        )
        .max(50, "Please ensure that the input value does not exceed 50."),
      carryOverDays: yup
        .number()
        .min(
          0,
          "Please make sure that the input value is greater than or equal to 0."
        )
        .max(50, "Please ensure that the input value does not exceed 50."),
      dob: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years."
        )
        .required("Please provide date of birth (DOB)."),
      jobCommencementDate: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .required("Please provide the job commenced date."),
    });

    return {
      workForceService,
      departments,
      workLocations,
      countries,
      fields,
      managers,
      gender,
      editEmployee,
      loadingContact,
      address,
      enabled,
      loading,
      canInviteOrEdit,
      calculateAge,
      save,
      initialize,
      router,
      route,
      useFormatDate,
      crumbs,
      isAdmin,
      canEdit,
      schema,
      message,
    };
  },
});
